import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Img from 'gatsby-image'
import ReactHtmlParser from 'react-html-parser'
import { Parallax } from 'react-scroll-parallax'
import { FormattedMessage } from 'react-intl'
import Layout from '../components/Layout'
import FlexibleContent from '../components/FlexibleContent'
import SingleOtherReference from '../components/SingleOtherReference'

export const PageTemplate = ({ lang, title, acf, otherReferences }) => {
  let linkToSite = null

  if (acf.link_to_site && acf.link_to_site !== 'tyhja') {
    linkToSite = (
      <a
        className="link-to-site arrow-link"
        href={acf.link_to_site}
        target="_blank"
        rel="noopener noreferrer"
      >
        {acf.link_to_site}
      </a>
    )
  }
  return (
    <article className="reference">
      {/* <Parallax className="parallax" y={[-15, 15]} tagInner="figure"> */}
      <figure className="section constrained flex reference-header">
        <Img
          src={acf.first_image.localFile.childImageSharp.fluid.src}
          fluid={acf.first_image.localFile.childImageSharp.fluid}
          className="size--full"
          loading="eager"
          fadeIn={false}
        />
        {/* <img src={acf.first_image.localFile.childImageSharp.fluid.src} /> */}
      </figure>
      {/* </Parallax> */}
      <header className="narrow section">
        <Link to="/work" className="arrow-back">
          <span className="icon-arrow-left" />
          <FormattedMessage id="our_selected_work" />
        </Link>
        <h1 className="h2">{acf.title}</h1>
        {ReactHtmlParser(acf.lead_text)}
        {linkToSite}
      </header>
      <FlexibleContent data={acf.flexible_content_reference} />
      {otherReferences && otherReferences.length ? (
        <aside className="section constrained">
          <h2>
            <FormattedMessage id="more_our_work" />
          </h2>
          <ul className="more_our_work">
            {otherReferences
              .filter(i => i.node.lang === lang)
              .filter((i, index) => index < 5)
              .map(otherReference => (
                <SingleOtherReference
                  data={otherReference}
                  key={otherReference.node.id}
                />
              ))}
            <li className="all">
              <Link to="/work">
                <div>
                  <FormattedMessage id="see_all" />
                  <span className="icon-arrow-right" />
                </div>
              </Link>
            </li>
          </ul>
        </aside>
      ) : null}
    </article>
  )
}

PageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
}

const Page = ({ data, pageContext }) => {
  const { wordpressWpReference: reference } = data
  const { otherReferences, id } = pageContext
  const { lang, translations } = reference

  const title = ReactHtmlParser(reference.title)

  const filteredOtherReferences = otherReferences.filter(otherReference => {
    if (otherReference.node.id === id) {
      return false
    }

    return true
  })

  return (
    <Layout lang={lang} translations={translations}>
      <Helmet title={`${title} | Don & Branco`} />
      <PageTemplate
        lang={lang}
        title={reference.title}
        acf={reference.acf}
        otherReferences={filteredOtherReferences}
      />
    </Layout>
  )
}

Page.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Page

export const pageQuery = graphql`
  query ReferenceById($id: String!) {
    wordpressWpReference(id: { eq: $id }) {
      id
      title
      lang
      translations {
        en
        fi
      }
      acf {
        title
        lead_text
        link_to_site

        first_image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 2560, quality: 100) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }

        flexible_content_reference {
          __typename
          ... on Node {
            ... on WordPressAcf_wysiwyg {
              class_name
              wysiwyg
            }

            ... on WordPressAcf_two_images {
              class_name
              image_sizes
              first_image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 2560, quality: 100) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }

              second_image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 2560, quality: 100) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }
            }

            ... on WordPressAcf_text_in_columns {
              class_name
              columns {
                column_title
                column_title_link
                lines {
                  text
                }
              }
            }

            ... on WordPressAcf_image {
              class_name
              full_width_image
              image_size
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 2560, quality: 100) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }
            }

            ... on WordPressAcf_links_to_references {
              class_name
              references_page_link
              references {
                link
                link_text
                image {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 2560, quality: 100) {
                        ...GatsbyImageSharpFluid_noBase64
                      }
                    }
                  }
                }
              }
            }

            ... on WordPressAcf_video {
              class_name
              video_file {
                localFile {
                  url
                }
              }
              full_width_video
            }

            ... on WordPressAcf_video_and_image {
              class_name
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 2560, quality: 100) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }
              video {
                localFile {
                  url
                }
              }
              alignment
            }

            ... on WordPressAcf_staff {
              class_name
              peeps {
                title
                name
                tel
                email
              }
            }

            ... on WordPressAcf_video_embed {
              class_name
              url
            }
          }
        }
      }
    }
  }
`
