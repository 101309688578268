import React, { Component } from 'react'
import Img from 'gatsby-image'
import { Link } from 'gatsby'

class SingleOtherReference extends Component {
  render() {
    const { data } = this.props

    return (
      <li>
        <Link to={data.node.path}>
          <div
            className="single-other-reference"
            style={{
              backgroundImage: `url(${data.node.acf.first_image.localFile.childImageSharp.fluid.src})`,
            }}
          />
          {data.node.title}
        </Link>
      </li>
    )
  }
}

export default SingleOtherReference
